.mapboxgl-ctrl-group {
  border-radius: 3rem !important;
  overflow: hidden !important;
  margin: 1rem !important;
  box-shadow: 0.4em 0.4em 1em 0.2em #0000004a !important;
}

.mapboxgl-ctrl-geolocate {
  width: auto !important;
  height: auto !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  gap: 0 !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  font-family: iranyekan !important;
  padding: 0.36rem 0.72rem 0.36rem 1rem !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  width: 2rem !important;
  height: 2rem !important;
  background-size: contain !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate::after {
  content: "مکان فعلی" !important;
}

.mapboxgl-control-container .map-type-control {
  display: none !important;
}
