.header-add-container {
  width: 100%;
  background-color: #8f9091;
}

.header-add {
  padding: 5px;
  display: block;
  line-height: 35px;
  text-align: center;
  width: 100%;
  background: 50% 100% / 50% 50% no-repeat
    radial-gradient(ellipse at bottom, #fff, transparent, transparent);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 120%;
  cursor: pointer;
  /* font-family: "Source Sans Pro", sans-serif; */
  animation: reveal 3000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;
}

@keyframes reveal {
  100% {
    background-size: 300% 300%;
  }
}

@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}

@media (max-width: 450px) {
  .header-add {
    font-size: 4.5cqmin;
  }
}

@media (prefers-color-scheme: dark) {
  .header-add-container {
    background-color: #556f72;
  }
}
