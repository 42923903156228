/**
*
*	Name:			IRANYekan Font
*	Version:			3.0
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		Dec 20, 2018
*	Updated on:		Dec 20, 2018
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت ایران یکان یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
IRANYekan fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
--------------------------------------------------------------------------------------
*	
**/
@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/eot/iranyekanwebboldfanum.eot");
  src: url("../fonts/eot/iranyekanwebboldfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebboldfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebboldfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/eot/iranyekanwebthinfanum.eot");
  src: url("../fonts/eot/iranyekanwebthinfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebthinfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebthinfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/eot/iranyekanweblightfanum.eot");
  src: url("../fonts/eot/iranyekanweblightfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanweblightfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanweblightfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/eot/iranyekanwebregularfanum.eot");
  src: url("../fonts/eot/iranyekanwebregularfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebregularfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebregularfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/eot/iranyekanwebmediumfanum.eot");
  src: url("../fonts/eot/iranyekanwebmediumfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebmediumfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebmediumfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/eot/iranyekanwebextraboldfanum.eot");
  src: url("../fonts/eot/iranyekanwebextraboldfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebextraboldfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebextraboldfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/eot/iranyekanwebblackfanum.eot");
  src: url("../fonts/eot/iranyekanwebblackfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebblackfanum.woff") format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebblackfanum.ttf") format("truetype");
}

@font-face {
  font-family: iranyekan;
  font-style: normal;
  font-weight: 950;
  src: url("../fonts/eot/iranyekanwebextrablackfanum.eot");
  src: url("../fonts/eot/iranyekanwebextrablackfanum.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-8 */ url("../fonts/woff/iranyekanwebextrablackfanum.woff")
      format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/iranyekanwebextrablackfanum.ttf") format("truetype");
}
