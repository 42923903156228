:root[data-theme="light"],
[data-theme][data-theme="light"] {
  --c--primary: #3182ceff;
  --c--background: #ffffffff;
  --c--background-variant: #1212120c;
  --c--surface: #f5f5f5ff;
  --c--subtitle: #12121272;
  --c--outline: #12121227;
  --c--shadow: #63636333;

  --c--on-primary: #ffffffff;
  --c--on-background: #121212ff;
  --c--on-surface: #121212ff;
  --c--on-alert: #ffffffff;

  --c--info: #3182ceff;
  --c--success: #38a169ff;
  --c--warning: #ecc94bff;
  --c--error: #e53e3eff;
}

:root[data-theme="dark"],
[data-theme][data-theme="dark"] {
  --c--primary: #a2d4ecff;
  --c--background: #2d3748ff;
  --c--background-variant: #ffffff32;
  --c--surface: #1a202cff;
  --c--subtitle: #ffffff72;
  --c--outline: #1b1b1b54;
  --c--shadow: #2020204f;

  --c--on-primary: #121212eb;
  --c--on-background: #ffffffeb;
  --c--on-surface: #ffffffeb;
  --c--on-alert: #121212eb;
  --c--pale-color: #ffffffeb;

  --c--info: #90cdf4ff;
  --c--success: #9ae6b4ff;
  --c--warning: #faf089ff;
  --c--error: #feb2b2ff;
}
