@font-face {
  font-family: vazirMatn;
  font-style: normal;
  font-weight: 300;
  src: 
    /* IE6-8 */ url("../fonts/woff2/Vazirmatn-Light.woff2")
      format("woff2"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("../fonts/ttf/Vazirmatn-Light.ttf")
      format("truetype");
}

@font-face {
  font-family: vazirMatn;
  font-style: normal;
  font-weight: 400;
  src: 
    /* IE6-8 */ url("../fonts/woff2/Vazirmatn-Regular.woff2")
      format("woff2"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/Vazirmatn-Regular.ttf") format("truetype");
}

@font-face {
  font-family: vazirMatn;
  font-style: normal;
  font-weight: 600;
  src: 
    /* IE6-8 */ url("../fonts/woff2/Vazirmatn-SemiBold.woff2")
      format("woff2"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
      url("../fonts/ttf/Vazirmatn-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: vazirMatn;
  font-style: normal;
  font-weight: 800;
  src: 
    /* IE6-8 */ url("../fonts/woff2/Vazirmatn-Bold.woff2")
      format("woff2"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("../fonts/ttf/Vazirmatn-Bold.ttf")
      format("truetype");
}

@font-face {
  font-family: vazirMatn;
  font-style: normal;
  font-weight: 900;
  src: 
    /* IE6-8 */ url("../fonts/woff2/Vazirmatn-Black.woff2")
      format("woff2"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url("../fonts/ttf/Vazirmatn-Black.ttf")
      format("truetype");
}
