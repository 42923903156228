@keyframes glowـanim {
  0% {
    filter: drop-shadow(0 0 1px #0fd1ad);
    transform: scale(0.96);
  }
  90% {
    filter: drop-shadow(0 0 7px #0fd1ad);
    transform: scale(1);
  }
  100% {
    filter: drop-shadow(0 0 7px #0fd1ad);
    transform: scale(1);
  }
}
