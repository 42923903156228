.comments {
  /* Hide Scrollbars */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* Hide Scrollbars */
.comments::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.comments__list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 1rem 0.15rem;
}

.comments__list__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

@media only screen and (min-width: 920px) {
  .comments {
    /* Show Scrollbars */
    -ms-overflow-style: auto; /* Internet Explorer 10+ */
    scrollbar-width: auto; /* Firefox */
  }

  /* Show Scrollbars */
  .comments::-webkit-scrollbar {
    display: inline-block;
    width: 1em;
  }

  .comments::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .comments::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border: 1px solid #f1f1f1;
  }

  .comments::-webkit-scrollbar-button {
    background-color: #f1f1f1;
  }

  .comments::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
}
