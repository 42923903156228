:root {
  --chakra-zIndices-modal: 999999999 !important;
  --toast-z-index: 9999999999 !important;
}

:root,
body {
  scroll-behavior: smooth;
}

.rising_card {
  transition: all;
  transition-duration: 300ms;
}

:root[data-theme="light"] .rising_card:active,
:root[data-theme="light"] .rising_card:hover {
  box-shadow: 0px 0px 18px -2px #00000026;
}

:root[data-theme="dark"] .rising_card:active,
:root[data-theme="dark"] .rising_card:hover {
  box-shadow: 0px 0px 18px -2px #ffffff26;
}

@keyframes pulse_anim {
  0% {
    box-shadow: 0 0 0 0px var(--chakra-colors-green-300),
      0 0 0 0px var(--chakra-colors-green-300);
  }
  100% {
    box-shadow: 0 0 0 30px #007ab800, 0 0 0 60px #007ab800;
  }
}

@keyframes pulse_sm_green_anim_half {
  0% {
    box-shadow: 0 0 0 0px #68d39166, 0 0 0 0px #68d39166;
  }
  100% {
    box-shadow: 0 0 0 7px #007ab800, 0 0 0 5px #007ab800;
  }
}

@keyframes pulse_sm_green_anim {
  0% {
    box-shadow: 0 0 0 0px var(--chakra-colors-green-300),
      0 0 0 0px var(--chakra-colors-green-300);
  }
  100% {
    box-shadow: 0 0 0 21px #007ab800, 0 0 0 15px #007ab800;
  }
}

@keyframes pulse_sm_red_anim {
  0% {
    box-shadow: 0 0 0 0px var(--chakra-colors-red-300),
      0 0 0 0px var(--chakra-colors-red-300);
  }
  100% {
    box-shadow: 0 0 0 21px #007ab800, 0 0 0 15px #007ab800;
  }
}

@keyframes pulse_sm_yellow_anim {
  0% {
    box-shadow: 0 0 0 0px var(--chakra-colors-yellow-300),
      0 0 0 0px var(--chakra-colors-yellow-300);
  }
  100% {
    box-shadow: 0 0 0 10px #007ab800, 0 0 0 5px #007ab800;
  }
}

@keyframes pulse_red_anim {
  0% {
    box-shadow: 0 0 0 0px var(--chakra-colors-red-300),
      0 0 0 0px var(--chakra-colors-red-300);
  }
  100% {
    box-shadow: 0 0 0 30px #007ab800, 0 0 0 60px #007ab800;
  }
}

@keyframes glowing_border_anim {
  to {
    background-position: 400% 0;
  }
}

@keyframes light_blur_anim {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(3px);
  }
}

/* Rainbow ------------------------------------- */
.glowing_rainbow_border {
  position: relative;
}

.glowing_rainbow_border:before,
.glowing_rainbow_border:after {
  content: "";
  position: absolute;
  left: -2.5px;
  top: -2.5px;
  background: linear-gradient(
    45deg,
    #fc6736,
    #f12711,
    #fc6736,
    #40e0e3,
    #40a2e3,
    #40e0e3,
    #fc6736,
    #f12711,
    #fc6736,
    #40e0e3,
    #40a2e3,
    #40e0e3,
    #fc6736,
    #f12711,
    #fc6736
  );
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glowing_border_anim 18s linear infinite;
  border-radius: 6px;
}

.glowing_rainbow_border:after {
  filter: blur(9px);
}

/* Golden -------------------------------------- */
.glowing_golden_border {
  position: relative;
}

.glowing_golden_border:before,
.glowing_golden_border:after {
  content: "";
  position: absolute;
  left: -2.5px;
  top: -2.5px;
  background: linear-gradient(
    45deg,
    #ffb122,
    #ffb122,
    #ffb122,
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    #ffb122,
    #ffb122,
    #ffb122
  );
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  background-size: 400%;
  animation: glowing_border_anim 15s linear infinite;
  border-radius: 6px;
}

.glowing_golden_border:after {
  filter: blur(9px);
}

/* Primary -------------------------------------- */
.glowing_primary_border {
  position: relative;
}

.glowing_primary_border:before,
.glowing_primary_border:after {
  content: "";
  position: absolute;
  left: -2.5px;
  top: -2.5px;
  background: linear-gradient(
    45deg,
    var(--chakra-colors-telegram-500),
    var(--chakra-colors-telegram-500),
    var(--chakra-colors-telegram-500),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-chakra-body-bg),
    var(--chakra-colors-telegram-500),
    var(--chakra-colors-telegram-500),
    var(--chakra-colors-telegram-500)
  );
  background-size: 400%;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  z-index: -1;
  animation: glowing_border_anim 18s linear infinite;
  border-radius: 6px;
}

.glowing_primary_border:after {
  filter: blur(0.3px);
}

@keyframes bounce_in {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes scale_up_y {
  from {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(1);
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide_floating_socials {
  0% {
    left: -4rem;
  }

  10% {
    left: 0;
  }

  90% {
    left: 0;
  }

  100% {
    left: -4rem;
  }
}

@keyframes flip_in_x {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}
